.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.boldPage{
  text-decoration: underline !important;
}


body{
  display: flex;
  height: 100vh;
}

.scrollSide-container{
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* homepage------------------------------------------------------------ */

  #home-page{
    overflow: hidden;
    background-image: url('pictures/background-images/home-page-background-2.png') ;
    background-size: cover;
  }
  
  .bigName{
    font-family: 'Montserrat', sans-serif;
    font-size: 8rem;
  }
  .smallName{
    font-family: 'Montserrat', sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
    width: 75rem;
  }

  .home-buttons{
    margin-top: 10rem;
  }

  .home-buttons > * {
    margin: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  .hashLink{
    cursor: pointer;
    color: black;
    width: auto;
    height: auto;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    font-size: 1rem;
    margin-left: 1.69rem;
    margin-right: 1.69rem;
  
    transition: all 650ms;
  }
  
  .hashLink:hover {
    font-size: 1.2rem;
    font-size-adjust: 20px;
    text-decoration: none;
    color: maroon;
  }

  .homeIcon{
    height: auto;
    width: 2rem;
    margin: 1rem;
    cursor: pointer;
    transition: all 650ms;
  }
  .homeIcon:hover {
    width: 2.5rem;
  }

/* homepage------------------------------------------------------------ */
/* __________________________________________________________________________________________________________________________________ */
/* aboutpage----------------------------------------------------------- */

  #about-page{
    background-color:#f1f1f1;
    overflow: hidden;
    background-image: url('pictures/background-images/about-me-background-2.png') ;
    background-size: cover;
  }

  .aboutMeTextContainer{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 1rem;
    font-size: 1.68rem;
    font-family: 'Montserrat', sans-serif;
    height: 7vmax;
    width: 69vmax;
  }

  .aboutMeIcon{
    height: auto;
    width: 8rem;
  }

  .aboutMeSelfie{
    height: auto;
    width: 8rem;
    border-radius: 10px;
  }

  .about-buttons > * {
    margin-top: 5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }


/* aboutpage----------------------------------------------------------- */
/* __________________________________________________________________________________________________________________________________ */
/* projectpage--------------------------------------------------------- */

  #projects-page{
    overflow: hidden;
    background-image: url('pictures/background-images/projects-page-background.png') ;
    background-size: cover;
    display: grid;
  }
  .card{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    width: 20rem;
    height: 35rem;
    border-radius: 25px !important;
  }

  .builtWith{
    height: 4rem;
    justify-content: center;
    align-items: center;

  }

  .builtWith > *{
      height: auto;
      width: 35px;
      margin: 15px;
  }

  .projects-row > * {
    margin: 1vw;
  }
  .projects-row {
    display: flex;
  }

  .card-title{
    font-size: 1.65rem !important;
  }

  .card-description{
    display: flex;
    height: 35%;
    color: #696969;
    font-size: .85rem;
    justify-content: center;
    align-items: center;
  }

  .project-card-buttons{
    border-width: .05rem;
    color: #696969;
    border-style: solid;
    border-radius: .5rem;
    width: auto;
  }

  .projectCard-button{
    font-size: .75rem !important;
    font-weight: bold !important;
    margin: 5px;
    background-color: white !important;
  }

  .card-img-top{
    cursor: pointer;
  }
  .project-buttons{
    margin-bottom: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

/* projectpage--------------------------------------------------------- */
/* __________________________________________________________________________________________________________________________________ */
/* contactpage--------------------------------------------------------- */

#contact-page{
  overflow: hidden;
  background-image: url('pictures/background-images/contact-page-background.png') ;
  background-size:cover;
  display: grid;
}

.contactContainer{
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vmax;
  width: 25vmax;
  border-width: .05rem;
  color: #696969;
  border-style: solid;
  border-radius: 0.75rem;
}
.contactIconLabel{
  margin: 1rem;
  font-weight: bold;
  cursor:copy;
}

#mailIconLabel{
  cursor:copy;
}
#mailIcon{
  width:4rem;
  height:auto;
  cursor:copy;
}
#linkedInIconLabel{
  cursor:pointer;
}
#linkedInIcon{
  width:3.9rem;
  height:auto;
  cursor:pointer;
}
.contact-buttons{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
/* .contactIcon{
  height: auto;
  width: 3.9rem;
} */
#githubIcon{
  height: auto;
  width: 3.9rem;
  cursor:pointer;
}
#githubIconLabel{
  cursor:pointer;
}
#devtoIcon{
  height: auto;
  width: 3.9rem;
  cursor:pointer;
}
#devtoIconLabel{
  cursor:pointer;
}
.contactIcon{
  margin: .3rem;
}

/* contactpage--------------------------------------------------------- */
/* __________________________________________________________________________________________________________________________________ */

/* MEDIA QUERIES */

/* 1366px and above: iPad PRO, HDready and FullHD desktop laptop monitors */
@media only screen and (min-width: 1366px) and (max-width: 1919px){
  /* _________________homepage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*homepage*_ */
  #home-page{
    overflow: hidden;
    background-image: url('pictures/background-images/home-page-background-2.png');
    background-size: cover;
  }
  .bigName{
    font-family: 'Montserrat', sans-serif;
    font-size: 8rem;
    margin-top: 25%;
  }
  .smallName{
    font-family: 'Montserrat', sans-serif;
    font-size: 2.3rem;
    margin-top: 25%;
  }
  /* _________________aboutpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*aboutpage*_ */
  .aboutMeTextContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    height: 7vmax;
    width: 69vmax;
  }
  .aboutMeIcon{
    height: auto;
    width: 4.5em;
    margin: .5em;
  }
  .col{
    margin-bottom: 0px !important;
  }
/* _________________projectspage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
.card{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  width: 17rem;
  height: 28rem;
  margin: 1.2rem;
}
.card-title{
  font-size: 1.7rem !important;
}
.builtWith{
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-bottom:0px;
}
.builtWith > *{
  height: auto;
  width: 25px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top:0px;
  margin-bottom:0px;
}
.card-description{
  display: flex;
  height: 45%;
  color: #696969;
  font-size: .69rem;
  justify-content: center;
  align-items: center;
}
.project-card-buttons{
  border-width: .05rem;
  color: #696969;
  border-style: solid;
  border-radius: .5rem;
  width: auto;
}
.projectCard-button{
  padding: 0.5px !important;
  font-size: .65rem !important;
  font-weight: bold !important;
  margin: 1.75px;
  background-color: white !important;
}
/* contactpage--------------------------------------------------------- */

.contactContainer{
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vmax;
  width: 30vmax;
  border-width: .05rem;
  color: #696969;
  border-style: solid;
  border-radius: 0.75rem;
}

}
/* 1024px to 1365px: most Android tablets and iPads in landscape mode, older desktop/laptop monitors */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {

  /* _________________homepage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*homepage*_ */
  #home-page{
    overflow: hidden;
    background-image: url('pictures/background-images/home-page-background-2.png');
    background-size: cover;
  }
  .bigName{
    font-family: 'Montserrat', sans-serif;
    font-size: 8rem;
    margin-top: 25%;
  }
  .smallName{
    font-family: 'Montserrat', sans-serif;
    font-size: 2.3rem;
    margin-top: 25%;
  }
  /* _________________aboutpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*aboutpage*_ */
  .aboutMeTextContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    height: 7.3vmax;
    width: 75vmax;
  }
  .col{
    margin-bottom: 0px !important;
  }
  .aboutMeIcon{
    width: 5rem;
    height: auto;
  }

  /* _________________projectspage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
  .card{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    width: 13rem;
    height: 25rem;
  }
  .card-title{
    font-size: 1.65rem !important;
  }
  .builtWith{
    height: 2.5rem;
    justify-content: center;
    align-items: center;
  }
  .builtWith > *{
    height: auto;
    width: 25px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top:0px;
    margin-bottom:0px;
  }
  .card-description{
    display: flex;
    height: 45%;
    color: #696969;
    font-size: .69rem;
    justify-content: center;
    align-items: center;
  }
  .project-card-buttons{
    border-width: .05rem;
    color: #696969;
    border-style: solid;
    border-radius: .5rem;
    width: auto;
  }
  .projectCard-button{
    padding: 0.5px !important;
    font-size: .65rem !important;
    font-weight: bold !important;
    margin: 1.5px;
    background-color: white !important;
  }
  /* _________________contactpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
  .contactContainer{
    height: 35rem;
    width: 25rem;
  }
  #mailIconLabel{
    cursor:copy;
  }
  #mailIcon{
    width:2.5rem;
    height:auto;
    cursor:copy;
  }
  #linkedInIconLabel{
    cursor:pointer;
  }
  #linkedInIcon{
    width:2.1rem;
    height:auto;
    cursor:pointer;
  }
  #devtoIcon{
    width:2.1rem;
    height:auto;
    cursor:pointer;
  }
  #githubIcon{
    width:2.1rem;
    height:auto;
    cursor:pointer;
  }
}
/* 768px to 1023px: most Android tablets and iPads in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px){
/* _________________homepage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*homepage*_ */
#home-page{
  overflow: hidden;
  background-image: url('pictures/background-images/home-page-background-2.png');
  background-size: cover;
}
.bigName{
  font-family: 'Montserrat', sans-serif;
  font-size: 6rem;
  margin-top: 25%;
}
.smallName{
  width: 42rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-top: 25%;
}
/* _________________aboutpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*aboutpage*_ */
.aboutMeTextContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  height: 12vmax;
  width: 69vmax;
}
.aboutMeIcon{
  height: auto;
  width: 4.5em;
  margin: .5em;
}
.col{
  margin-bottom: 0px !important;
}
/* _________________projectspage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
#projects-page{
  overflow: hidden;
  background-image: url('pictures/background-images/projects-page-background.png') ;
  background-size: cover;
  display: flexbox;
}
.card{
font-family: 'Montserrat', sans-serif;
font-weight: bold;
width: 9rem;
height: 28rem;
margin: 1.2rem;
}
.card-title{
font-size: 1.1rem !important;
}
.builtWith{
  margin: .5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-bottom:0px;
}
.builtWith > *{
height: auto;
width: 24px;
margin-left: 10px;
margin-right: 10px;
margin-top:0px;
margin-bottom:0px;
}
.card-description{
display: flex;
height: 65%;
color: #696969;
font-size: .69rem;
justify-content: center;
align-items: center;
margin-bottom: 0px ;
}
.project-card-buttons{
border-width: .05rem;
color: #696969;
border-style: solid;
border-radius: .5rem;
width: auto;
}
.projectCard-button{
padding: 0.5px !important;
font-size: .65rem !important;
font-weight: bold !important;
margin: 1.75px;
background-color: white !important;
}
/* contactpage--------------------------------------------------------- */
#contact-page{
  overflow: hidden;
  background-image: url('pictures/background-images/contact-page-background.png') ;
  background-size:cover;
}

.contactContainer{
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vmax;
  width: 43vmax;
  border-width: .15rem;
  color: #696969;
  border-style: none;
  border-radius: 0.75rem;
}
.contactIconLabel{
  margin: .1rem;
  font-weight: bold;
  font-size: 1rem;
  cursor:copy;
}

#mailIconLabel{
  cursor:copy;
}
#mailIcon{
  width:3rem;
  height:3rem;
  cursor:copy;
  margin-right: 1rem;
  margin-left: 3.5rem;
}
#linkedInIconLabel{
  cursor:pointer;
}
#linkedInIcon{
  width:3rem;
  height:3rem;
  cursor:pointer;
  margin-right: 1rem;
  margin-left: 3.5rem;
}
#githubIcon{
  width:3rem;
  height:3rem;
  cursor:pointer;
  margin-right: 1rem;
  margin-left: 3.5rem;
}
#devtoIcon{
  width:3rem;
  height:3rem;
  cursor:pointer;
  margin-right: 1rem;
  margin-left: 3.5rem;
}

}
/* 421px to 767px width: most smartphones in landscape mode */
@media only screen and (min-width: 421px) and (max-width: 767px){
  .hashLink{
    height: auto;
    width: auto;
    margin-left: .9rem ;
    margin-right: .9rem ;
  }
/* _________________homepage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*homepage*_ */
#home-page{
  overflow: hidden;
  background-image: url('pictures/background-images/home-page-background-2.png');
  background-size: cover;
}
.bigName{
  font-family: 'Montserrat', sans-serif;
  font-size: 6rem;
  margin-top: 25%;
}
.smallName{
  width:29rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-top: 25%;
}
/* _________________aboutpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*aboutpage*_ */
.aboutMeTextContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  font-size: .82rem;
  font-family: 'Montserrat', sans-serif;
  height: 12vmax;
  width: 69vmax;
}
.aboutMeIcon{
  height: auto;
  width: 4.5em;
  margin: .5em;
}
.col{
  margin-bottom: 0px !important;
}
/* _________________projectspage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
#projects-page{
  overflow: hidden;
  background-image: url('pictures/background-images/projects-page-background.png') ;
  background-size: cover;
  display: flexbox;
}
.card{
font-family: 'Montserrat', sans-serif;
font-weight: bold;
width: 9rem;
height: 28rem;
margin: 1.2rem;
}
.card-title{
font-size: 1.1rem !important;
}
.builtWith{
  margin: .5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-bottom:0px;
}
.builtWith > *{
height: auto;
width: 24px;
margin-left: 10px;
margin-right: 10px;
margin-top:0px;
margin-bottom:0px;
}
.card-description{
display: flex;
height: 65%;
color: #696969;
font-size: .69rem;
justify-content: center;
align-items: center;
margin-bottom: 0px ;
}
.project-card-buttons{
border-width: .05rem;
color: #696969;
border-style: solid;
border-radius: .5rem;
width: auto;
}
.projectCard-button{
padding: 0.5px !important;
font-size: .65rem !important;
font-weight: bold !important;
margin: 1.75px;
background-color: white !important;
}
/* contactpage--------------------------------------------------------- */
#contact-page{
  overflow: hidden;
  background-image: url('pictures/background-images/contact-page-background.png') ;
  background-size:cover;
}

.contactContainer{
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vmax;
  width: 43vmax;
  border-width: .15rem;
  color: #696969;
  border-style: none;
  border-radius: 0.75rem;
}
.contactIconLabel{
  margin: .1rem;
  font-weight: bold;
  font-size: 1rem;
  cursor:copy;
}

#mailIconLabel{
  cursor:copy;
}
#mailIcon{
  width:3rem;
  height:3rem;
  cursor:copy;
  margin-right: 1rem;
  margin-left: 3.5rem;
}
#linkedInIconLabel{
  cursor:pointer;
}
#linkedInIcon{
  width:3rem;
  height:3rem;
  cursor:pointer;
  margin-right: 1rem;
  margin-left: 3.5rem;
}
#githubIcon{
  width:3rem;
  height:3rem;
  cursor:pointer;
  margin-right: 1rem;
  margin-left: 3.5rem;
}
#devtoIcon{
  width:3rem;
  height:3rem;
  cursor:pointer;
  margin-right: 1rem;
  margin-left: 3.5rem;
}

}

/* 420px maximum width: cover all smartphones in portrait mode */
@media only screen and (min-width: 321px) and (max-width: 430px){
  body{
    display: inline;
    height: 100vh;
  }
  
  .scrollSide-container{
    min-width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* _________________homepage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*homepage*_ */
  #home-page{
    overflow: hidden;
    background-image: url('pictures/background-images/home-page-background-2-mobile.png');
    background-size: cover;
  }
  .bigName{
    font-family: 'Montserrat', sans-serif;
    font-size: 4.5rem;
    margin-top: 35%;
  }
  .smallName{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    margin-top: 16.79% ;
    margin-left: 50vmax;
    margin-right: 50vmax;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hashLink{
    color: black;
    width: auto ;
    height: auto;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    font-size: .8rem;
    margin: .75rem;
  
    transition: all 650ms;
  }
  /* _________________aboutpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*aboutpage*_ */
  #about-page{
    background-color:#f1f1f1;
    height: auto;
    overflow: hidden;
    background-image: url('pictures/background-images/about-me-background-2-mobile.png') ;
    background-size: cover;
  }
  .aboutMeTextContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    font-size: 1.1rem;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    width: auto;
  }
  .descriptionText{
    padding: 0px !important;
    max-width: 12rem !important;
  }
  .aboutMeIcon{
    height: auto;
    width: 4.5em;
    margin: .5em;
  }
  .col{
    margin-bottom: 0px !important;
  }
  /* _________________projectspage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
  #projects-page{
    height: auto;
    overflow: hidden;
    background-image: url('pictures/background-images/projects-page-background-mobile.png') ;
    background-size:contain;
  }

  .projects-row {
    display:block;
  }
  .card{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    width: 20rem;
    height: 30rem;
    margin: 5rem;
  }
  .card-title{
    font-size: 2rem !important;
  }
  .builtWith{
    height: 2.5rem;
    justify-content: center;
    align-items: center;
  }
  .builtWith > *{
    height: auto;
    width: 25px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top:0px;
    margin-bottom:0px;
  }
  .card-description{
    display: flex;
    height: 45%;
    color: #696969;
    font-size: .9rem;
    justify-content: center;
    align-items: center;
  }
  .project-card-buttons{
    border-width: .05rem;
    color: #696969;
    border-style: solid;
    border-radius: .5rem;
    width: auto;
  }
  .projectCard-button{
    padding: 0.5px !important;
    font-size: .65rem !important;
    font-weight: bold !important;
    margin: 1.5px;
    background-color: white !important;
  }
   /* _________________contactpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
  /* contactpage--------------------------------------------------------- */

  #contact-page{
    overflow: hidden;
    background-image: url('pictures/background-images/contact-page-background.png') ;
    background-size:cover;
  }

  .contactContainer{
    margin: 10px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vmax;
    width: 43vmax;
    border-width: .15rem;
    color: #696969;
    border-style: none;
    border-radius: 0.75rem;
  }
  .contactIconLabel{
    margin: .1rem;
    font-weight: bold;
    font-size: 1rem;
    cursor:copy;
  }

  #mailIconLabel{
    cursor:copy;
  }
  #mailIcon{
    width:2rem;
    height:2rem;
    cursor:copy;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
  #linkedInIconLabel{
    cursor:pointer;
  }
  #linkedInIcon{
    width:2rem;
    height:2rem;
    cursor:pointer;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
  #githubIcon{
    width:2rem;
    height:2rem;
    cursor:pointer;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
  #devtoIcon{
    width:2rem;
    height:2rem;
    cursor:pointer;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
}

@media  (max-width: 320px){
  body{
    display: inline;
    height: 100vh;
  }
  
  .scrollSide-container{
    min-width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* _________________homepage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*homepage*_ */
  #home-page{
    overflow: hidden;
    background-image: url('pictures/background-images/home-page-background-2-mobile.png');
    background-size: cover;
  }
  .bigName{
    font-family: 'Montserrat', sans-serif;
    font-size: 4.5rem;
    margin-top: 35%;
  }
  .smallName{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    margin-top: 16.79% ;
    margin-left: 50vmax;
    margin-right: 50vmax;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hashLink{
    color: black;
    width: auto;
    height: auto;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    font-size: .8rem;
    margin: .25rem;
  
    transition: all 650ms;
  }
  /* _________________aboutpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*aboutpage*_ */
  #about-page{
    height: auto;
    background-color:#f1f1f1;
    overflow: hidden;
    background-image: url('pictures/background-images/about-me-background-2-mobile.png') ;
    background-size: cover;
  }
  .aboutMeTextContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    width: auto;
  }
  .descriptionText{
    padding: 0px !important;
    max-width: 12rem !important;
  }
  .aboutMeIcon{
    height: auto;
    width: 4.5em;
    margin: .5em;
  }
  .col{
    margin-bottom: 0px !important;
  }
  /* _________________projectspage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
  #projects-page{
    height: auto;
    overflow: hidden;
    background-image: url('pictures/background-images/projects-page-background-mobile.png') ;
    background-size:contain;
  }

  .projects-row {
    display:block;
  }
  .card{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    width: 18rem;
    height: 30rem;
    margin: 5rem;
  }
  .card-title{
    font-size: 1.65rem !important;
  }
  .builtWith{
    height: 2.5rem;
    justify-content: center;
    align-items: center;
  }
  .builtWith > *{
    height: auto;
    width: 25px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top:0px;
    margin-bottom:0px;
  }
  .card-description{
    display: flex;
    height: 45%;
    color: #696969;
    font-size: .69rem;
    justify-content: center;
    align-items: center;
  }
  .project-card-buttons{
    border-width: .05rem;
    color: #696969;
    border-style: solid;
    border-radius: .5rem;
    width: auto;
  }
  .projectCard-button{
    padding: 0.5px !important;
    font-size: .65rem !important;
    font-weight: bold !important;
    margin: 1.5px;
    background-color: white !important;
  }
   /* _________________contactpage____________________________________*_*_*__*_**_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*__*__*_*_*_*_*_*_*_**_*_*_*_*_*_*_*_*_*_*projectspage*_ */
  /* contactpage--------------------------------------------------------- */

  #contact-page{
    overflow: hidden;
    background-image: url('pictures/background-images/contact-page-background.png') ;
    background-size:cover;
  }

  .contactContainer{
    margin: 10px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vmax;
    width: 43vmax;
    border-width: .15rem;
    color: #696969;
    border-style: none;
    border-radius: 0.75rem;
  }
  .contactIconLabel{
    margin: .1rem;
    font-weight: bold;
    cursor:copy;
    font-size: .67rem;
  }

  #mailIconLabel{
    cursor:copy;
  }
  #mailIcon{
    width:1.1rem;
    height:1.1rem;
    cursor:copy;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
  #linkedInIconLabel{
    cursor:pointer;
  }
  #linkedInIcon{
    width:1.1rem;
    height:1.1rem;
    cursor:pointer;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
  #githubIcon{
    width:1.1rem;
    height:1.1rem;
    cursor:pointer;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
  #devtoIcon{
    width:1.1rem;
    height:1.1rem;
    cursor:pointer;
    margin-right: 1rem;
    margin-left: 3.5rem;
  }
}